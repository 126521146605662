import dayjs from 'dayjs';

const locale = {
  name: 'ja',
  weekdays: '日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日'.split('_'),
  weekdaysShort: '日曜日_月曜日_火曜日_水曜日_木曜日_金曜日_土曜日'.split('_'),
  weekdaysMin: '日_1_2_3_4_5_6'.split('_'),
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
  formats: {
    LTS: 'HH:mm:ss',
    LT: 'ah:mm',
    L: 'YYYY/MM/DD',
    LL: 'YYYY年M月D日',
    LLL: 'YYYY年M月D日 ah:mm',
    LLLL: 'YYYY年M月D日dddd HH:mm',
    l: 'YYYY/M/D',
    ll: 'YYYY年M月D日',
    lll: 'YYYY年M月D日 HH:mm',
    llll: 'YYYY年M月D日dddd HH:mm',
  },
  meridiem: (hour) => (hour >= 12 ? '午後' : '午前'),
  relativeTime: {
    future: '%s以内',
    past: '%s前',
    s: '数秒',
    m: '1分',
    mm: '%d 分',
    h: '1時間',
    hh: '%d 時間',
    d: '1日',
    dd: '%d 日',
    M: '1ヶ月',
    MM: '%d か月',
    y: '1年',
    yy: '%d 年',
  },
  ordinal: (n) => `${n}日`,
};

dayjs.locale(locale, null, true);

export default locale;
