const common = {
  Test: 'テスト',
  Save: '保存',
  Add: '新規追加',
  Download: 'ダウンロード',
  Cancel: 'キャンセル',
  Export: 'エクスポート',
  Import: 'インポート',
  OK: '了解',
};

export default common;
