import { FileErrorTypes } from 'hooks/useSelectFile';
import { XlsxFileErrorTypes } from 'hooks/useSelectXlsxFile';
import { PurchaseOrdersErrorTypes } from 'features/PurchaseOrderBulkImport/constants';

const purchaseOrderBulkImport = {
  Inventory: '在庫管理',
  'Purchase Order': '入荷リスト',
  'Page Create Title': '入荷リストを一括で作成',
  'Page Update Title': '入荷リストを一括で更新',
  'ID Reference File': 'ID対象ファイル',
  'Id Reference Section Title': 'ID対象ファイルをダウンロード',
  'Export purchase order ID reference file':
    '入荷リストID対象ファイルのエクスポート',
  'Id Reference Section Description':
    'まずID対象ファイルをダウンロードしてください。このファイルには支店情報、スタッフ情報、商品および仕様が含まれており、名前と ID を対象して一括でインポートされた入荷リストを編集できます。多数の商品および仕様がある場合は、ダウンロード前に一部の商品をフィルタリングすることをお勧めします。',
  'Sample Section Title': 'サンプルとファイルをダウンロード',
  'Create Sample Section Description1':
    '下の「サンプルファイルをダウンロード」をクリックして、入荷リスト一括作成する方法を確認できます。',
  'Create Sample Section Description2':
    'ファイル内の「Template」シートに入荷リスト情報を入力してください。システムは最初のシートのみを読み取るため、ファイルの最初のシート情報が正しい形式で入力されているか確認してください。一度に最大 50 件作成できます。ファイル内の「Instructions」および「Examples」を参照して入力できます。',
  'Update Sample Section Description1':
    '下の [更新する入荷リストを選択] をクリックして、編集したい複数の入荷リストが選択された後、既存の入荷リストと商品の詳細が直接エクスポートされ編集できるようになります。このファイルには、複数の入荷リストの一括更新方法手順も含まれています。 [空欄のサンプルファイルをダウンロード] をクリックしてフォームに入力することもできます。このファイルには既存の情報は含まれていません。',
  'Update Sample Section Description2':
    '「Purchase Order List」から修正してください。システムは最初のシートのみを読み取るため、ファイルの最初のシート情報が正しい形式で入力されているか確認してください。一度に最大 50 件作成できます。ファイル内の「Instructions」を参照して入力できます。',
  'Update Sample Section Description3':
    '注意：一括で更新できるのは、ステータスが「新規注文」および「入荷中」の入荷リストのみです。',
  'Create Sample Section Btn': 'サンプルファイルをダウンロード',
  'Update Sample Section Btn1': '更新する入荷リストを選択',
  'Update Sample Section Btn2': '空欄のサンプルファイルをダウンロード',
  'Upload Section Title': '完成したファイルをアップロード',
  'Upload Section Description': 'アップロードファイルを選択',
  'Upload Section Hint':
    'サイズは 10 MB 未満、.xlsx 形式のみアップロード可能です。',
  'Upload Section Btn': '新しいファイルをアップロード',
  [`File_Error_${FileErrorTypes.InvalidFileSize}`]:
    'ファイルサイズは10MBを超えることはできません',
  [`File_Error_${FileErrorTypes.InvalidFileType}`]:
    'ファイル形式は .xlsx である必要があります',
  [`File_Error_${XlsxFileErrorTypes.InvalidContent}`]:
    'ファイル内容とファイル形式は .xlsx である必要があります',
  [`File_Error_${PurchaseOrdersErrorTypes.InvalidOrderCounts}`]:
    '入荷リストは 50 を超えることはできません。',
  'Upload Section Error Msg': '入荷リストは 50 を超えることはできません。',
  Category: '商品分類',
  Supplier: 'サプライヤー',
  'All Product Categories': 'すべての商品カテゴリ',
  'All Suppliers': 'すべてのサプライヤー',
  'No Results': '結果がありません',
  'Export Purchase Order Records': '入荷履歴のエクスポート',
  'Search Purchase Order Number': '注文書番号を検索',
  'All Stores': 'すべての支店',
  'View Selected Items': '選択した商品を表示',
  '{{selected}} / {{total}} selected': '選択済み {{selected}} / {{total}}',
  'Purchase Order Number / Custom ID': '入荷番号/カスタム番号',
  'Order Status': '入荷ステータス',
  'Arrival Status': '到着ステータス',
  'Creation Date': '作成日',
  'View All Items': 'すべての商品を見る',
  'No purchase order selected': '入荷リストが選択されていません',
  'Export bulk update purchase order sample file':
    '一括更新入荷リストのサンプルファイルをエクスポート',
  'Exporting your bulk update purchase order sample file':
    '一括更新入荷リストのサンプルファイルをエクスポートしています',
  EXPORT_DESCRIPTION_PURCHASE_ORDERS_REFERENCE:
    'データは {{email}} に送信されています。時間がかかる場合がございますので、しばらくお待ちください。 <redirection>こちらこをクリック<icon></icon></redirection>して、エクスポートの進行状況を確認およびサンプルファイルをダウンロードできます。',
  'No purchase order': '入荷リストなし',
  'Please go to Inventory Management > Purchase Order to create or import a purchase order':
    '在庫管理 > 入荷リスト　から追加またはインポートしてください',
  New: '新規注文',
  Received: '在庫あり',
  Removed: '削除済み',
  'None arrived': '未到着',
  'Partial arrived': '一部到着',
  'All arrived': '到着済み',
  'None returned': '未返品',
  Returned: '返品済み',
  'Please try other keywords or search criteria':
    '他のキーワードまたは検索条件を試してください',
  'Exporting your ID reference file':
    '入荷リストID 対象ファイルをエクスポートしています',
  EXPORT_DESCRIPTION_ID_REFERENCE:
    'データは {{email}} に送信されています。時間がかかる場合がありますので、しばらくお待ちください。 <redirection>こちらをクリック<icon></icon></redirection>して、エクスポートの進行状況確認、およびファイルをダウンロードできます。',
  'Failed to upload': 'アップロードに失敗しました',
  'Failed to export': 'エクスポートに失敗しました',
  'Error description':
    'エラーが発生しました。ネットワーク接続を確認して再試行するか、カスタマーセンターまでお問い合わせください。',
};

export default purchaseOrderBulkImport;
